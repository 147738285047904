// USA
export const locale = {
  MAIN_MENU: {
    DASHBOARD: 'Dashboard',
    ADD: 'Add',
    BACK_TO_LIST: 'Back to list',
    COMPANY: 'Company',
    COMPANIES: 'Companies',
    MY_COMPANIES: 'My Company',
    ALL_COMPANIES: 'All Companies',
    DAILY_REVENUE: 'Daily Revenue',
    ADD_NEW_COMPANY: 'Add New',
    USERS: 'Users',
    ALL_USERS: 'All Users',
    ADD_NEW_USER: 'Add New User',
    LOGGERS: 'Loggers',
    ALL_LOGGERS: 'All Loggers',
    ADD_NEW_LOGGER: 'Add New',
    DEVICES: 'Devices',
    NO_RESULTS_FOUND: 'No Results Found',
    ALL_DEVICES: 'All Devices',
    ADD_NEW_DEVICE: 'Add New Device',
    CRITICAL_HISTORY: 'Critical History',
    SERVICE_MODE_HISTORY: 'Service Mode History',
    MAINTENANCE: 'Maintenance',
    MAINTENANCE_BY_DEVICE: 'By Device',
    MAINTENANCE_IN_RANGE: 'By Range',
    DEVICES_MAP: 'Devices Map',
    CITIES: 'Cities',
    ALL_CITIES: 'All Cities',
    ADD_NEW_CITY: 'Add New',
    LESSORS: 'Lessors',
    LESSOR: 'Lessor',
    ALL_LESSORS: 'All Lessors',
    ADD_NEW_LESSOR: 'Add New',
    LOCATIONS: 'Locations',
    ADD_LOCATION: 'Add Location',
    ALL_LOCATIONS: 'All Locations',
    ADD_NEW_LOCATION: 'Add New',
    TAGS: 'Tags',
    ALL_TAGS: 'All Tags',
    ADD_NEW_TAG: 'Add New',
    DEPOSITS: 'Deposits',
    VIEW: 'View',
    ADD_NEW_DEPOSITS: 'Add New',
    DELETE_DEPOSITS: 'Delete',
    DETAILED_REVIEW: 'Detailed Review',
    TODAY_REVENUE: 'Today Revenue',
    FINANCE_REPORT: 'Finance Report',
    FINANCE_REPORTS: 'Financial Analysis',
    TOKEN_REPORTS: 'Token Analysis',
    LOGISTIC_REPORTS: 'Logistic Analysis',
    FINANCE_REPORTS_BY_DEVICES: 'By Device',
    FINANCE_REPORTS_BY_LOCATIONS: 'By Location',
    FINANCE_REPORTS_BY_LESSOR: 'By Lessors',
    FINANCE_REPORTS_BY_COMPANY: 'By Company',
    COMPARATIVE_ANALYSIS: 'Comparative Analysis',
    COMPARATIVE_ANALYSIS_BY_DEVICES: 'By Device',
    COMPARATIVE_ANALYSIS_BY_LOCATIONS: 'By Location',
    COMPARATIVE_ANALYSIS_BY_LESSOR: 'By Lessors',
    TRANSACTIONS: 'Transactions',
    BILLS: 'Bills',
    ALL_BILLS: 'All Bills',
    CANCELLATION_BILL: 'Cancellation',
    NEW_BILL: 'Create New',
    SETTINGS: 'Settings',
    GENERATE: 'Generate',
    IMPORT: 'Imports',
    COMPANIES_AND_USERS: 'Companies & Users',
    LOYALTY: 'Loyalty',
    LOGGERS_AND_DEVICES: 'Loggers & Devices',
    CITIES_LESSORS_AND_TAGS: 'Cities, Lessors & Tags',
    REPORTS: 'Reports',
    TRAFIC: 'Traffic',
    EXPORTS: 'Exports',
    EXPORT: 'Export',
    TRANSACTIONS_BILLS_COMPARISON: 'Transactions-Bills Comparison',
    OWNERS: 'Owners',
    CARDS: 'Cards',
    CARD_UPLOAD: 'Card Upload',
    CARD_TRANSACTIONS: 'Card Transactions',
    OWNER_TRANSACTIONS: 'Owner Transactions',
    USER_TRANSACTION: 'User Transactions',
    REPORT: 'Report',
    OWNER_REPORT: 'Owner Report',
    USER_REPORT: 'User Report',
    CARD_REPORT: 'Card Report',
    PROMOTIONS: 'Promotions',
    POS_OWNERS: 'POS Owners',
    POS_LOCATIONS: 'POS Locations',
    POS_WORKERS: 'POS Workers',
    COMPANY_USERS: 'Company users',
  },
  PAGES: {
    DASHBOARD: 'Dashboard',
    DEVICES: 'Devices',
    CRITICAL_DEVICES: 'Critical Devices',
    LESSORS: 'Lessors',
    ALL_LESSORS: 'All Lessors',
    UPDATE_LESSORS: 'Edit Lessor',
  },
  WIDGET: {
    SALES_STAT: 'Sales Stat',
    WEEKLY_SALES: 'Weekly Sales',
    NEW_USERS: 'New Users',
    ITEM_ORDERS: 'Item Orders',
    BUG_REPORTS: 'Bug Reports',
    WEEKLY_INCOME: 'Weekly Income',
    GENERATE_REPORT: 'Generate Report',
  },
  GENERAL: {
    RESET: 'Reset',
    AUTHORS: 'Authors',
    GROUPS: 'Groups',
    TRENDS: 'Trends',
    ORDER: 'Order',
    EVENT: 'Event',
    REPORT: 'Report',
    POST: 'Post',
    FILE: 'File',
    CHOOSE_LABEL: 'Choose Label',
    CUSTOMER: 'Customer',
    PARTNER: 'Partner',
    SUPLIER: 'Suplier',
    MEMBER: 'Member',
    STAFF: 'Staff',
    CALLS: 'Calls',
    SETTINGS: 'Settings',
    HELP: 'Help',
    PRIVACY: 'Privacy',
    VENDING_TYCOON_INFO:
      'Vending Tycoon system is currently in update phase from v1.2 to complete new v2.0. In the next few days, system failures are possible. Thanks for understanding.',
    YES: 'Yes',
    NO: 'No',
    HI: 'Hi',
    SELECT_ALL: 'Select All',
    NONE: 'None',
    SHOW_ALL: 'Show All',
    ADD: 'Add',
    CLOSE: 'Close',
    DAILY_INDEX: 'Daily Index',
    LAST_YEAR_DAILY_INDEX: 'Last Year Daily Index',
    WEEKLY_INDEX: 'Weekly Index',
    LAST_YEAR_WEEKLY_INDEX: 'Last Year Weekly Index',
    MONTHLY_INDEX: 'Monthly Index',
    LAST_YEAR_MONTHLY_INDEX: 'Last Year Monthly Index',
    YEARLY_INDEX: 'Annual Index',
    DEVICES_TOTAL: 'Total Devices',
    PROBLEMATIC_TOTAL: 'Problematic Devices',
    CRITICAL_TOTAL: 'Critical Devices',
    CURRENT_BALANCE: 'Current Balance',
    CURRENT_MONTH_REVENUE: 'Current Month Revenue',
    LAST_MONTH_REVENUE: 'Last Month Revenue',
    TRAFFIC_FOR_THE_LAST_30_DAYS: 'Traffic for the last 30 days',
    CUMULATIVE: 'Cumulative',
    CANCEL: 'Cancel',
    REMOVE_ALL: 'Remove All',
    COIN: 'Coin',
    ALL_COINS_IN_DEVICES: 'All Coins In Devices',
    ALL_TRANSACTIONS_IN_THIS_MONTH: 'All Transactions in this Month',
    ALL_TRANSACTIONS_IN_PREVIOUS_MONTH: 'All Transactions in previous Month',
    GENERATE_REPORT: 'Generate Report',
    GENERATE_EXPORTS: 'Generate Exports',
    SHOW_DEVICES_CHART: 'Show Devices Chart',
    PREDICT_TRAFFIC: 'Predict Trafic',
    USER_PROFILE: 'User Profile',
    USER_PROFILE_DETAILS: 'Account settings and more',
    SIGN_OUT: 'Sign Out',
    TRAFFIC_LAST_365_DAYS: 'Trafic Last 365 Days',
    ON: 'On',
    OFF: 'Off',
    ACTIONS: 'Actions',
    DATE: 'Date',
    DATE_AND_TIME: 'Date & Time',
    TOTAL: 'Total',
    LOCATION: 'Location',
    LOCATIONS_NUMBER: 'Number of locations',
    AMOUNT: 'Amount',
    REPORTED_AMOUNT: 'Reported Amount',
    PAYMENT_TYPE: 'Payment Type',
    NAME: 'Name',
    DESCRIPTION: 'Description',
    VAT: 'VAT',
    ADDRESS: 'Address',
    PHONE: 'Phone',
    EMAIL: 'Email',
    WEEKS: 'Weeks',
    MONTH: 'Month',
    WEEK: 'Week',
    DAY: 'Day',
    PAID: 'Paid',
    YEAR: 'Year',
    SAVE: 'Save',
    SAVE_AND_EXIT: 'Save & Exit',
    SAVE_AND_ADD_NEW: 'Save & Add New',
    OPTIONS: 'Options',
    PURCHASE: 'Purchase',
    SALE: 'Sale',
    TIME: 'Time',
    SEARCH: 'Search',
    NO_RECORD_FOUND: 'No record found',
    LOADING: 'Loading',
    UPDATE: 'Update',
    DEVICE: 'Device',
    ENTER_DEVICE_NAME: 'Enter device name',
    DEVICES_LOCATIONS: 'Device Locations',
    SEND: 'Send',
    ACTION: 'Action',
    RESULTS: 'Results',
    USER: 'User',
    LESSOR: 'Lessor',
    ADVANCED_VIEW: 'Advanced View',
    SIMPLE_VIEW: 'Simple View',
    ROLE: 'Role',
    TOKEN_DROP_DEVICE: 'Token Drop Device',
    TOKENS: 'Tokens',
    COLUMNS_SELECT: 'Columns Select',
    FINANCE_TOTAL: 'Finance Total',
    NETTO_COIN: 'Netto Coin',
    NETTO_SMS: 'Netto SMS',
    SMS: 'SMS',
    NETTO_CARD: 'Netto Card',
    CARD: 'Card',
    NETTO_CSC: 'Netto CSC',
    LOYALTY: 'Loyalty',
    PDV_CSC: 'Pdv CSC',
    NETTO: 'Netto income',
    NETTO_LEASE: 'Netto lease',
    NETTO_PRIZE_COST: 'Netto prize cost',
    DEVICE_LOCATION_EBITDA: 'Device/location ebitda',
    COMPANY_NETTO: 'Company netto',
    APPLY: 'Apply',
    ENTER_USER_EMAIL: 'Enter user email',
  },
  BUTTON: {
    ADD_COMPANY: 'Add New Company',
  },
  FORMS: {
    REQUIRED_FIELDS: 'all fields are required',
  },
  DASHBOARD: {
    SHOW_GRAPHS: 'Show Graphs',
    ALIGN_WEEKENDS: 'Align Weekends',
  },
  DEVICE_PERFORMANCE_GRAPH: {
    PERFORMING_DEVICE: 'performing device',
    BEST: 'Best',
    WORST: 'Worst',
  },
  USERS: {
    EDIT_USER: 'Edit User',
    DELETE_USER: 'Delete User',
  },
  LOCATIONS: {
    EDIT_LOCATION: 'Edit Location',
    DELETE_LOCATION: 'Delete Location',
  },
  DEVICES: {
    JUNIOR_DEVICE_QUESTIONNAIRE: 'Junior device questionnaire',
    SHOW_TAX_STICKER: 'Show Tax Sticker',
    SHOW_QR_CODE: 'Show QR Code',
    EMPTY_DEVICE: 'Empty Device',
    EDIT_DEVICE: 'Edit Device',
    DELETE_DEVICE: 'Delete Device',
    DEVICES_NUMBER: 'Devices Number',
    LAST_EMPTY: 'Last Empty At',
    SELECT_LOCATION: 'Select location',
    INSERTED_COINS: 'Inserted coins',
    INSERTED_TOKENS: 'Inserted tokens',
  },
  COMPANIES: {
    NAME_AND_OIB: 'Name & OIB',
    DATE_AND_TIME: 'Date & Time',
    TOKEN_TYPE: 'Token Type',
    TOKEN_AMOUNT: 'Quantity',
    MODULE: 'Module',
    MODULES: 'Modules',
    MODULE_COMPONENTS: 'Module Components',
    DEVICE_AND_LESSOR: 'Device & Lessor',
    SERVICE_MODE: 'Service Mode',
    COMPANY_DAILY_REVENUE: 'Company Daily Revenue',
    TRANSACTIONS_NUMBER: 'Transactions number',
    DEVICE_NAME: 'Device name',
    COMPANY_INFO: 'Company Information',
    COMPANY_INFO_DETAIL: 'Ime, Email, OIB, VAT',
    MODULES_AND_LOGGERS: 'Modules & Loggers',
    MODULES_AND_LOGGERS_DETAIL: 'Select Active Modules',
    USERS: 'Users',
    USERS_DETAIL: 'Adding Users',
    FINAL_CHECK: 'Final Check',
    FINAL_CHECK_DETAIL: 'View & Edit',
    IN_VAT_SYSTEM: 'In VAT System',
    SEND_EMAIL_NOTIFICATIONS: 'Send Email Notifications',
    NOTIFICATION_EMAILS: 'Notification Emails',
    NOTIFICATION_EMAIL: 'Notification Email',
    DAILY_REPORTS: 'Daily Reports',
    DEPOSIT_NOTIFICATIONS: 'Deposit Notifications',
    DEVICE_EMPTY: 'Device Empty Event Notifications',
    INDEX_CHARTS: 'Index Charts',
    DEVICES_REVENUE: 'Devices Last 30 Days Revenue',
    REPORTS_IN_RANGE: 'Report By Days In Range',
    AR_DAYS_IN_WEEK: 'AR Days In Week',
    HOURS_OF_THE_DAY: 'Hours Of The Day',
    CHECK_INPUT: 'Check input and edit the company',
    OPERATIONAL_COSTS: 'Operational Costs',
    SHOW_USERS: 'Show users',
    SHOW_LOGGERS: 'Show loggers',
    SHOW_DETAILED_VIEW: 'Device detailed view',
    SHOW_TRANSACTIONS: 'Show transactions',
    FORMS: {
      COMPANY_NAME: 'Enter the company name',
      SHORT_DESCRIPTION: 'Enter short description',
      OIB_NUMBER: 'Enter OIB number',
      COMPANY_ADDRESS: 'Enter company address',
      CONTACT_PHONE: 'Enter contact phone',
      EMAIL_ADDRESS: 'Enter email address',
      SELECT_MODULE: 'Select Module',
      SELECT_USERS: 'Select Users',
      PREVIOUS_STEP: 'Previous Step',
      EDIT_COMPANY: 'Edit Company',
      NEXT_STEP: 'Next Step',
    },
    LOCATION_NAME: 'Location name',
    LESSOR_NAME: 'Lessor name',
    UNVERIFIED_DEPOSIT_NOTIFICATION: 'Unverified Deposits Notification',
  },
  ARTICLES: {
    ARTICLES: 'Articles',
    ENTER_LESSOR_NAME: 'Enter Lessor Name',
    ALL_ARTICLES: 'All Articles',
    UPDATE_ARTICLES: 'Update Article',
    ARTICLE_NAME: 'Article Name',
    NET_PURCHASE_PRICE: 'Net Purchase Price',
    NET_SELLING_PRICE: 'Net Selling Price',
    GROSS_PURCHASE_PRICE: 'Gross Purchase Price',
    GROSS_SALES_PRICE: 'Gross Selling Price',
    UNIT: 'Unit',
    SUPPLIER: 'Supplier',
    NET_PRICE: 'Net Price',
    GROSS_PRICE: 'Gross Price',
    REPOSITORIES: 'Repositories',
    EDIT_REPOSITORY: 'Edit Repository',
    ENTER_REPOSITORY_NAME: 'Enter Repository Name',
    QUANTITY: 'Quantity',
    ADD_ARTICLE: 'Add Article',
    SELECT_ARTICLE: 'Select Article',
  },
  DROPDOWN: {
    MY_CART: 'My Cart',
    ITEMS: 'Items',
    FOR: 'for',
    SUB_TOTAL: 'Sub total',
    PLACE_ORDER: 'Place order',
    QUICK_ACTIONS: 'Quick Actions',
    TASKS_PENDING: 'tasks pending',
  },
  PROMO_BOX: {
    EDIT_PROMO_BOX: 'Edit Promo Box',
    ALL_PROMO_BOXES: 'All Promo Boxes',
    UPDATE_PROMO_BOX: 'Update Promo Box',
    ADD_NEW: 'Add New',
    PROMO_BOXES: 'Promo Boxes',
    LOYALTY_PROMO_BOXES: 'Loyalty Promo Boxes',
    TITLE: 'Title',
    BACKGROUND_COLOR: 'Background Color',
    TEXT_COLOR: 'Text Color',
    ACTION_BUTTON: 'Action Button',
    OPTIONS: 'Options',
    DELETE_CONFIRMATION_TITLE: 'Deleted',
    DELETE_CONFIRMATION_TEXT: 'Promo Box has been deleted successfully.',
    DELETE_ERROR_TITLE: 'Error',
    DELETE_ERROR_TEXT:
      'An error occurred while trying to delete the Promo Box.',
    LOADING_MESSAGE: 'Loading Promo Boxes...',
    ENTER_TITLE_PLACEHOLDER: 'Enter promo box title',
    SELECT_BACKGROUND_COLOR: 'Select Background Color',
    SELECT_TEXT_COLOR: 'Select Text Color',
    SELECT_ACTION_BUTTON: 'Select Action Button',
    INCLUDE_ACTION_BUTTON: 'Include Action Button',
    YES: 'Yes',
    NO: 'No',
    PROFILE: 'Profile',
    CREATE_SUCCESS: 'Promo Box has been created successfully.',
    CREATE_ERROR: 'An error occurred while creating the Promo Box.',
  },
  QUICK_PANEL: {
    AUDIT_LOGS: 'Audit Logs',
    NOTIFICATIONS: 'Notifications',
    SETTINGS: 'Settings',
    SYSTEM_MESSAGES: 'System Messages',
    CUSTOMER_CARE: 'Customer Care',
    ENABLE_NOTIFICATIONS: 'Enable Notifications',
    ENABLE_CASE_TRACKING: 'Enable Case Tracking',
    SUPPORT_PORTAL: 'Support Portal',
    ENABLE_REPORT_EXPORT: 'Enable Report Export',
    ALLOW_DATA_COLLECTION: 'Allow Data Collection',
    REPORTS: 'Reports',
    GENERATE_REPORTS: 'Generate Reports',
    MEMBERS: 'Members',
    ENABLE_MEMBERS_SIGNUP: 'Enable Member singup',
    ALLOW_USER_FEEDBACKS: 'Allow User Feedbacks',
    ENABLE_CUSTOMER_PORTAL: 'Enable Customer Portal',
  },
  DROPDOWN_NOTIFICATION: {
    UNFISCALIZED_BILLS: 'Unfiscalized Bills',
    UNFISCALIZED: 'Unfiscalized',
    LAST_TEN: 'Last 10',
    ALL_FISCALIZED: 'All bills are fiscalized...',
    CAUGHT_UP: 'All caught up!',
    NO_NOTIFICATIONS: 'No new notifications.',
    UNVERIFIED_DEPOSITS: 'Unverified deposits',
    UNVERIFIED: 'Unverified',
    ALL_VERIFIED: 'All deposits are verified...',
  },
  BILLS: {
    FILTER_BILLS: 'Filter Bills',
    ENTER_BUSINESS_LABEL: 'Enter Business Label',
    ENTER_DEVICE_IMEI: 'Enter Device IMEI',
    ENTER_BUSINESS_LABEL_OR_DEVICE_IMEI: 'Enter Business Label or IMEI',
    BILL_INFO: 'Enter bill info',
    BILL_NUMBER: 'Bill number',
    BUSINESS_LABEL: 'Business label',
    ISU_NUMBER: 'ISU number',
    ADD_BILL: 'Add Bill',
    REQUEST_BILL: 'Request Bill',
    SELECT_DEVICES: 'Select Devices',
    CREATE_TRANSACTION: 'Create Transaction',
    UNFISCALIZED_BILLS: 'Unfiscalized Bills',
    FISCAL: 'Fiscal',
  },
  CITIES: {
    ALL_CITIES: 'All Cities',
    UPDATE_CITY: 'Update City',
    CITIES: 'Cities',
    ADD_NEW_CITY: 'Add New City',
    ADD_CITY: 'Add City',
    CITY_NAME: 'City Name',
    LOCATIONS_NUMBER: 'Locations Number',
  },
  COMPARATIVE_ANALYSIS: {
    DEVICE_A_REPORT: 'Device A Report',
    DEVICE_B_REPORT: 'Device B Report',
    LESSORS_A_REPORT: 'Lessors A Report',
    LESSORS_B_REPORT: 'Lessors B Report',
    LOCATION_A_REPORT: 'Location A Report',
    LOCATION_B_REPORT: 'Location B Report',
    AVERAGE_REPORT_BY_DAYS: 'Average report by days in week',
    DEVICES_SELECTION: 'Devices Selection',
  },
  LESSORS: {
    SHOW_INFO: 'Show Lessor Details',
    EDIT_LESSOR: 'Edit Lessor',
    DELETE_LESSOR: 'Delete Lessor',
    CONTRACT_START_DATE: 'Contract Start Date',
    CONTRACT_END_DATE: 'Contract End Date',
    RENTAL_TYPE: 'Rental Type',
    PAYMENT_INSURANCE: 'Payment Insurance',
    RENTAL_PERCENTAGE: 'Rental Percentage',
    INCOME_LIMIT: 'Income Limit',
    CONTRACT_RENTAL_AMOUNT: 'Contract Rental Amount',
  },
  LOGISTIC_ANALYSIS: {
    DEVICE_FILTERS: 'Device Filters',
    APPLY_FILTER: 'Please apply one of the filters above.',
    NO_DATA: 'There are no devices.',
    SALDO_REDUCERS: 'Saldo Reducers',
    MONSTERS: 'Monsters',
    PRIZE_DEVICES: 'Prize Devices',
  },
  LOCATION_MANAGEMENT: {
    CITY: 'City',
    MAP: 'Map',
    REMOVE: 'Remove',
    MONDAY: 'Monday',
    TUESDAY: 'Tuesday',
    WEDNESDAY: 'Wednesday',
    THURSDAY: 'Thursday',
    FRIDAY: 'Friday',
    SATURDAY: 'Saturday',
    SUNDAY: 'Sunday',
    START_TIME: 'Start time',
    END_TIME: 'End time',
    LESSOR: 'Lessor',
    LOCATION_NAME: 'Location name',
    LOCATION_ACTIVE: 'Location active',
    EDIT_LOCATION: 'Update location',
    DESCRIPTION: 'Description',
    LESSOR_RENT: 'Lessor rent',
    RENTAL_TYPE: 'Rental type',
    CONTRACT_RENTAL_AMOUNT: 'Contract Rental Amount',
    INCOME_LIMIT: 'Income limit',
    RENTAL_PERCENTAGE: 'Rental Percentage',
    RESPONSIBLE_WORKER: 'Responsible Worker',
    NEW_RESPONSIBLE_WORKER: 'New Responsible Worker',
    WORKING_TIME: 'Working time',
    ADDRESS: 'Address',
    STREET_NAME: 'Street Name',
    FISCALISATION: 'Fiscalisation',
    CHOOSE_PAYMENT_METHOD: 'Choose payment method',
  },
  DEPOSITS: {
    REPORT_DEPOSIT_AMOUNT: 'Report deposit amount',
    REPORT_SUCCESS: 'Report created successfully.',
    VERIFICATION_SUCCESS: 'Verification successful.',
    LOCATION_SEARCH: 'Search for a location',
    PENDING_VERIFICATION: 'Pending verification',
    VERIFY_SELECTED: 'Verify Selected',
    DEVICE_NAME: 'Device name',
    DEPOSIT: 'Deposit',
    DEPOSITS: 'deposits',
    REPORTED: 'Reported',
    DIFFERENCE: 'Difference',
    NO_DATA: 'No data for the selected timeframe',
    REPORTED_AMOUNT: 'Reported amount',
    REPORT_AMOUNT: 'Report amount',
    VERIFY_DEPOSIT: 'Verify Deposit',
    VERIFY_CONFIRM: 'Are you sure you want to verify this deposit?',
    VERIFY_MULTIPLE_CONFIRM: 'Are you sure you want to verify',
    VERIFY_ALL: 'Verify All',
    TOKEN_DEPOSIT: 'Token Deposit',
    TOKEN_REPORTED: 'Token Reported',
    TOKEN_DIFFERENCE: 'Token Difference',
  },
  DISCHARGE: {
    DISCHARGE_TIME: 'Discharge time',
    DISCHARGE_HISTORY: 'Discharge History',
  },
}
